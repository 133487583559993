<template>
  <div class="tgxxtj">
    <el-row style="display: flex; align-items: center">
      <el-col :span="4">
        <h3>托管数据统计</h3>
      </el-col>
      <el-col :span="3" :offset="17">
        <el-button size="mini" type="primary" @click="njzyjk">
          社会化服务监测
        </el-button>
      </el-col>
    </el-row>

    <el-form :inline="true">
      <el-form-item label="统计时间：">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="small"
          value-format="yyyy-MM-dd"
          @change="changeTime"
        >
        </el-date-picker>
      </el-form-item>
      <!-- {{ identityObj }} -->
      <el-form-item label="区域：">
        <el-select v-model="selectAreacode" size="small" @change="changeArea">
          <el-option value="all" :label="identityObj.areaName">{{
            identityObj.areaName
          }}</el-option>
          <el-option
            v-for="(item, index) in options"
            :key="index"
            :value="item.xzqbh"
            :label="item.name"
            >{{ item.name }}</el-option
          >
        </el-select>
      </el-form-item>
      <span class="tip">(此图仅展示当年成交数据前六的区域)</span>
    </el-form>
    <BarChart
      class="chart1"
      :xData="xData"
      :seriesData="seriesData"
      :changeEchart="changeEchart"
    />

    <h3 style="margin-top: 50px">
      {{ titleName }}托管数据统计
      <span>（统计时间：{{ startTime }}-{{ endTime }}）</span>
      <download-excel
        class="export-excel-wrapper"
        :data="tableList"
        :fields="json_fields"
        type="xls"
        worksheet="My Worksheet"
        :name="titleName + '托管统计' + '(' + startTime + '-' + endTime + ')'"
      >
        <el-button type="primary" size="small">导出统计结果</el-button>
      </download-excel>
    </h3>
    <table border="1">
      <thead>
        <tr>
          <td>区域</td>
          <td>托管面积（亩）</td>
          <td>托管金额（万元）</td>
          <td>托管农户数量</td>
          <td>托管组织数量</td>
          <td>托管宗数</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in tableList" :key="index">
          <td>{{ item.city }}</td>
          <td>{{ item.sumArea }}</td>
          <td>{{ item.sumMoney }}</td>
          <td>{{ item.farmerNumber }}</td>
          <td>{{ item.companyNumber }}</td>
          <td>{{ item.orderNumber }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import BarChart from "@/components/echart/tgxxtjChart.vue";
import { tgsjCountData, getXzqArr, selectListById } from "@/api/add";
import { mapGetters } from "vuex";
import { formatMoney } from "../../../../js/utils/util.js";
export default {
  components: {
    BarChart,
  },
  data() {
    return {
      json_fields: {
        区域: "city",
        "托管面积（亩）": "sumArea",
        "托管金额（万元）": "sumMoney",
        托管农户数量: "farmerNumber",
        托管组织数量: "companyNumber",
        托管宗数: "orderNumber",
      },
      titleName: "",
      selectAreacode: "all",
      changeEchart: false,
      options: [],
      areacode: "",
      time: ["", ""],
      tableList: [],
      xData: [""],
      flag: "",
      seriesData: [],
      baseData: [
        {
          name: "托管面积（亩）",
          key: "sumArea",
          value: [],
        },
        {
          name: "托管金额（万元）",
          key: "sumMoney",
          value: [],
        },
        {
          name: "托管农户（人）",
          key: "farmerNumber",
          value: [],
        },
        {
          name: "托管组织数",
          key: "companyNumber",
          value: [],
        },
      ],
      deptId: null,
    };
  },
  created() {
    this.startTime = this.$moment().format("YYYY-01-01");
    this.endTime = this.$moment().format("YYYY-MM-DD");
    this.time = [this.startTime, this.endTime];
    this.titleName = this.identityObj.areaName;
    this.deptId = this.userInfo.deptId;
    this.areacode = this.identityObj.areaCode;
    this.flag = this.identityObj.identityTypeCode == 7 ? "Y" : "N";
    this.initData();
    this.getRegions();
    console.log(JSON.parse(JSON.stringify(this.userInfo)));
    console.log(this.userInfo.deptId);
  },
  computed: mapGetters(["userInfo", "identityObj"]),
  methods: {
    njzyjk() {
      window.open(window.location.origin + `#/njjg`, "_blank");
    },
    exportExcel() {
      const data = this.tableList;
      const ws = XLSX.utils.aoa_to_sheet([data]);
      const wbout = XLSX.write(ws, { bookType: "xlsx", type: "array" });
      const blob = new Blob([wbout], { type: "application/octet-stream" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "table-list.xlsx";
      link.click();
    },
    changeTime(val) {
      this.startTime = val[0];
      this.endTime = val[1];
      this.initData();
    },
    changeArea(val) {
      console.log("val", val);
      if (val === "all") {
        this.titleName = this.identityObj.areaName;
        this.areacode = this.identityObj.areaCode;
        this.deptId = this.userInfo.deptId;
        this.flag = this.identityObj.identityTypeCode == 7 ? "Y" : "N";
        this.initData();
      } else {
        this.areacode = val;
        this.titleName = this.getTitleName(val);
        this.deptId = this.getTitledeptId(val);
        this.flag = "N";
        this.initData();
      }
    },
    getTitleName(val) {
      console.log("val", val);
      let name = "";
      this.options.forEach((item) => {
        if (item.id == val) {
          name = item.name;
        }
      });
      return name;
    },
    getTitledeptId(val) {
      let deptId = "";
      this.options.forEach((item) => {
        if (item.id == val) {
          deptId = item.deptId;
        }
      });
      return deptId;
    },
    async getRegions() {
      //根据该用户所在的行政区，往下多获取一层
      let level = 3;
      if (this.identityObj.areaCode.length == 6) {
        level = 4;
      }
      if (this.identityObj.areaCode.length == 4) {
        level = 3;
      }
      let res = await selectListById({
        // level: level,
        deptId: this.userInfo.deptId,
        areaCode: this.identityObj.areaCode,
      });
      if (res) {
        this.options = res;
        console.log("888888888888888888", res);
        console.log(JSON.parse(JSON.stringify(res)));
      }
    },
    async initData() {
      //根据条件搜索数据
      let res = await tgsjCountData({
        flag: this.flag,
        areaCode: this.areacode,
        startTime: this.startTime,
        endTime: this.endTime,
        deptId: this.deptId,
      });
      let xData = [];
      let seriesObj = {
        sumArea: [],
        sumMoney: [],
        farmerNumber: [],
        companyNumber: [],
      };
      res.data.forEach((item) => {
        item.sumMoney =
          item.sumArea > 0 ? formatMoney(item.sumMoney / 10000, 4) : 0;
        item.sumArea = item.sumArea > 0 ? formatMoney(item.sumArea, 4) : 0;
        xData.push(item.city);
        for (let key in item) {
          if (seriesObj[key]) {
            seriesObj[key].push(item[key]);
          }
        }
      });
      //构造列表数据
      this.tableList = res.data;
      console.log(res.data, "查看");
      this.xData = xData.slice(0, 6);
      this.baseData.forEach((item) => {
        item.value = seriesObj[item.key];
      });
      //echart option需要的格式样式
      this.seriesData = this.baseData;
      this.changeEchart = !this.changeEchart;
      console.log(this.seriesData, "查看这一块的数据");
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-form {
  .el-form-item {
    margin-bottom: 10px;
    margin-left: 12px;
  }
  .tip {
    display: inline-block;
    float: right;
    margin-right: 20px;
    color: #7f7f7f;
    line-height: 40px;
  }
}
h3 {
  span {
    display: inline-block;
    font-weight: normal;
    font-size: 16px;
  }
  .el-button {
    float: right;
    margin-top: -30px;
  }
}
.tgxxtj {
  box-sizing: border-box;
  padding: 0 30px 24px 30px;
  .chart1 {
    height: 500px;
    width: 100%;
  }
  table {
    border-collapse: collapse;
    border-color: #c0c4cc;
    width: 100%;
    thead {
      background: #f3f3f3;
      td {
        text-align: center;
        padding: 10px 0;
      }
    }
    tbody {
      td {
        text-align: center;
        padding: 8px 0;
      }
    }
  }
}
</style>
